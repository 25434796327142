import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { SiteInfoService } from '@shared/api/site-info.service';
import { SiteInfoDTO } from '@shared/dto/accounts/models';

@Pipe({
  name: 'getSiteInfo',
})
export class GetSiteInfoPipe implements PipeTransform {
  constructor(private siteInfoService: SiteInfoService) {}

  public transform(type: SiteInfoDTO.TypeEnum): Observable<SiteInfoDTO> {
    return this.siteInfoService.getByType(type);
  }
}
