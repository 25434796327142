import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SiteInfoDTO } from '@shared/dto/accounts/site-info-dto';
import { AppScrollStrategy } from 'app/config/scroll-strategy';
import { SiteInfoDialogComponent } from '../site-info-dialog/site-info-dialog.component';
import { SiteInfoDialogData } from './site-info-trigger.types';

@Component({
  selector: 'app-site-info-trigger',
  templateUrl: './site-info-trigger.component.html',
  styleUrl: './site-info-trigger.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteInfoTriggerComponent {
  @Input({ required: true }) public type: SiteInfoDTO.TypeEnum;

  @HostListener('click') public _onClick(): void {
    this.dialog.open<SiteInfoDialogComponent, SiteInfoDialogData, void>(SiteInfoDialogComponent, {
      width: '450px',
      minHeight: '300px',
      maxHeight: '100vh',
      scrollStrategy: new AppScrollStrategy(),
      panelClass: ['app-dialog-popup', '--border-r4'],
      backdropClass: 'app-dialog-backdrop',
      data: { type: this.type },
    });
  }

  constructor(private dialog: MatDialog) {}
}
