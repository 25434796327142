<app-grid [cols]="2"
          gap="50px"
          class="top-gutter-2"
          *ngIf="privateEquityData$|async as data">
  <app-grid-tile>
    <div class="app-item-list">
      <div class="app-item-list-title h3-b">general information</div>

      <div class="app-item-list-item">
        <span class="h4-m">Website</span>
        <a [appHref]="data.website"
           class="b4-r">{{ data.website || '-' }}</a>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Entity Types</span>
        <span class="b4-r">{{ data.entityType || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Formerly Known As</span>
        <span class="b4-r">{{ data.formarlyKnownAs || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Legal Name</span>
        <span class="b4-r">{{ data.legalName || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Business Status</span>
        <span class="b4-r">{{ data.businessStatus || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Ownership Status</span>
        <span class="b4-r">{{ data.ownershipStatus || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Financing Status</span>
        <span class="b4-r">{{ data.financingStatus || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Year Founded</span>
        <span class="b4-r">{{ data.yearFounded || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Universe</span>
        <span class="b4-r">{{ data.universe || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Employees</span>
        <span class="b4-r">{{ data.employees || '-' }}</span>
      </div>
    </div>
  </app-grid-tile>

  <app-grid-tile>
    <div class="app-item-list">
      <div class="app-item-list-title h3-b">industry/vertical</div>

      <div class="app-item-list-item">
        <span class="h4-m">Primary Industry</span>
        <span class="b4-r">{{ data.primaryIndustry || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Other Industry</span>
        <span class="b4-r">{{ data.otherIndustries || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Verticals</span>
        <span class="b4-r">{{ _convertVerticalsToString(data.verticals) || '-' }}</span>
      </div>
    </div>
  </app-grid-tile>
</app-grid>