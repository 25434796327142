import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InstrumentDTO } from '@shared/dto/gateway-secured/models';

@Component({
  selector: 'app-position-information',
  templateUrl: './position-information.component.html',
  styleUrls: ['./position-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PositionInformationComponent {
  @HostBinding('class.app-position-information') public _hostClass = true;

  public instrumentTiny$ = new BehaviorSubject<Record<string, number | string>>(null);

  @Input() public set instrumentTiny(value: Record<string, number | string>) {
    this.instrumentTiny$.next(value);
  }

  @Input() public InstrumentAssetType: InstrumentDTO.AssetTypeEnum;

  constructor() {}
}
