<app-grid [cols]="2"
          gap="50px"
          class="top-gutter-2"
          *ngIf="realEstateData$|async as data">
  <app-grid-tile>
    <div class="app-item-list">
      <div class="app-item-list-title h3-b">general information</div>

      <div class="app-item-list-item">
        <span class="h4-m">Address</span>
        <span class="b4-r"
              *ngLet="{
                _address: data?.address,
                _streetNumber: data?.address?.streetNumber ? data.address.streetNumber + ', ' : '' ,
                _zipCode: data?.address?.zipCode || ''
               } as obj">
          <ng-container *ngIf="obj._address; else showEmptyAddress">
            {{ obj._address.country + ', ' + obj._address.city + ', ' + obj._address.street + ', ' + obj._streetNumber + obj._zipCode }}
          </ng-container>
          <ng-template #showEmptyAddress>-</ng-template>
        </span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Tenant</span>
        <span class="b4-r">{{ data.tenant || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">For Rent</span>
        <span class="b4-r">{{ data.forRent | appBooleanToWord }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Gross Rent</span>
        <span class="b4-r">{{ data.grossRent || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Net Rent</span>
        <span class="b4-r">{{ data.netRent || '-' }}</span>
      </div>
    </div>
  </app-grid-tile>

  <app-grid-tile [align]="'LeftBottom'">
    <div class="app-item-list">
      <div class="app-item-list-item">
        <span class="h4-m">Type</span>
        <span class="b4-r">{{ data.type || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Floor</span>
        <span class="b4-r">{{ data.floor || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Living Space</span>
        <span class="b4-r">{{ data.livingSpace || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Number Of Rooms</span>
        <span class="b4-r">{{ data.noRooms || '-' }}</span>
      </div>

      <div class="app-item-list-item">
        <span class="h4-m">Number Of Bathrooms</span>
        <span class="b4-r">{{ data.noBathrooms || '-' }}</span>
      </div>
    </div>
  </app-grid-tile>
</app-grid>
