import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SiteInfoService } from '@api/site-info.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisclaimerComponent {
  public data$ = this.siteInfoService.getByType('DISCLAIMER');

  constructor(private siteInfoService: SiteInfoService) {}
}
