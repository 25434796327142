import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PrivateEquityDTO, VerticalDTO } from '@shared/dto/alternative-assets/models';

@Component({
  selector: 'app-position-private-equity-information',
  templateUrl: './position-private-equity-information.component.html',
  styleUrls: ['./position-private-equity-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PositionPrivateEquityInformationComponent {
  @HostBinding('class.app-position-private-equity-information') public _hostClass = true;

  public privateEquityData$ = new BehaviorSubject<PrivateEquityDTO>(null);

  @Input() public set privateEquityData(value: PrivateEquityDTO) {
    this.privateEquityData$.next(value);
  }

  constructor() {}

  public _convertVerticalsToString(verticals: VerticalDTO[]): string {
    return verticals ? verticals.map((v) => v.name).join(', ') : undefined;
  }
}
