<ng-container *ngIf="instrumentTiny$|async as instrument">
  <app-grid [cols]="2"
            gap="50px"
            class="top-gutter-2">
    <app-grid-tile>
      <div class="app-item-list">
        <div class="app-item-list-title h3-b">
          {{ 'dialogs.position.information.summary'|translate }}
        </div>

        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.isin'|translate }}</span>
          <span class="b4-r">{{ instrument['ISIN'] || '-' }}</span>
        </div>

        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.sector'|translate }}</span>
          <span class="b4-r">{{ instrument['Sector'] || '-' }}</span>
        </div>

        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.BICsLevel2'|translate }}</span>
          <span class="b4-r">{{ instrument['BICs Level 2'] || '-' }}</span>
        </div>

        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.countryOfRisk'|translate }}</span>
          <span class="b4-r">{{ instrument['Country of Risk'] || '-' }}</span>
        </div>

        <div *ngIf="InstrumentAssetType === 'SP'"
             class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.startDate'|translate }}</span>
          <span class="b4-r">{{ instrument['Start Date']| appDate:false:'h:mm' || '-' }}</span>
        </div>

        <div *ngIf="InstrumentAssetType === 'SP'"
             class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.paymentDate'|translate }}</span>
          <span class="b4-r">{{ instrument['Payment Date']| appDate:false:'h:mm' || '-' }}</span>
        </div>

        <div *ngIf="InstrumentAssetType === 'SP'"
             class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.nextObservationDate'|translate }}</span>
          <span class="b4-r">{{ instrument['nextCouponDate']| appDate:false:'h:mm' || '-' }}</span>
        </div>

        <div *ngIf="InstrumentAssetType === 'SP'"
             class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.finalObservationDate'|translate }}</span>
          <span class="b4-r">{{ instrument['Final Observation Date']| appDate:false:'h:mm' || '-' }}</span>
        </div>

        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.maturity'|translate }}</span>
          <span class="b4-r">{{ instrument['Maturity']| appDate:false:'h:mm' || '-' }}</span>
        </div>
      </div>

      <div class="app-item-list">
        <div class="app-item-list-title h3-b">
          {{ 'dialogs.position.information.ratings'|translate }}
        </div>

        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.moodys'|translate }}</span>
          <span class="b4-r">{{ instrument['Moody\'s'] || '-' }}</span>
        </div>
        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.fitch'|translate }}</span>
          <span class="b4-r">{{ instrument['Fitch'] || '-' }}</span>
        </div>
        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.SnP'|translate }}</span>
          <span class="b4-r">{{ instrument['snp'] || '-' }}</span>
        </div>
      </div>
    </app-grid-tile>

    <app-grid-tile>
      <div class="app-item-list">
        <div class="app-item-list-title h3-b">
          {{ 'dialogs.position.information.companyInformation'|translate }}
        </div>

        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.capitalization'|translate }}</span>
          <span class="b4-r">{{ (instrument["Capitalization"] | toNumber | appNumber) || '-' }}</span>
        </div>

        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.profitability'|translate }}</span>
          <span class="b4-r">{{ (instrument["Profitability"] | toNumber | appNumber) || '-' }}</span>
        </div>

        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.salesGrowthYear'|translate }}</span>
          <span class="b4-r">{{ (instrument["Sales Growth Year"] | toNumber | appNumber) || '-' }}</span>
        </div>

        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.assetGrowthYear'|translate }}</span>
          <span class="b4-r">{{ (instrument["Asset growth year"] | toNumber | appNumber) || '-' }}</span>
        </div>

        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.debtGrowthYear'|translate }}</span>
          <span class="b4-r">{{ (instrument['Debt growth year'] | toNumber | appNumber) || '-' }}</span>
        </div>

        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.netIncome'|translate }}</span>
          <span class="b4-r">{{ (instrument["Net Income"] | toNumber | appNumber) || '-' }}</span>
        </div>

        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.shortLongDebt'|translate }}</span>
          <span class="b4-r">{{ (instrument["Short Long Debt"] | toNumber | appNumber) || '-' }}</span>
        </div>

        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.revenue'|translate }}</span>
          <span class="b4-r">{{ (instrument["Revenue"] | toNumber | appNumber) || '-' }}</span>
        </div>

        <div class="app-item-list-item">
          <span class="h4-m">{{ 'dialogs.position.information.totalCapital'|translate }}</span>
          <span class="b4-r">{{ (instrument["Total capital"] | toNumber | appNumber) || '-' }}</span>
        </div>
      </div>
    </app-grid-tile>
  </app-grid>
</ng-container>
