<div class="app-flex-row">
  <span class="h4-sp w-100 m-b-12">
    Disclaimer
  </span>

  <app-svg [icon]="'Close_24'"
           (click)="_onCloseClick()"
           class="app-bg-05 app-border-r2 m-l-10">
  </app-svg>
</div>

@let _data = data$|async;

@if (loading$|async) {
<app-spinner></app-spinner>
} @else {
<app-site-info-outlet [data]="_data"
                      [textClass]="'b3-r'">
</app-site-info-outlet>
}