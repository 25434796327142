import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { SiteInfoDTO } from '@shared/dto/accounts/models';

@Component({
  selector: 'app-site-info-outlet',
  templateUrl: './site-info-outlet.component.html',
  styleUrls: ['./site-info-outlet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'app-text-color-50',
    '[class]': `textClass || 'b5-r'`,
  },
})
export class SiteInfoOutletComponent {
  @Input() public data: SiteInfoDTO;
  @Input() public showExpander: boolean = false;
  @Input() public textClass: string;

  public expanded = false;

  @HostBinding('class.app-site-info-outlet--expanded')
  public get _expanded(): boolean {
    return this.showExpander && this.expanded;
  }

  constructor() {}
}
