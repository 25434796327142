import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { RealEstateDTO } from '@shared/dto/alternative-assets/models';

@Component({
  selector: 'app-position-real-estate-information',
  templateUrl: './position-real-estate-information.component.html',
  styleUrls: ['./position-real-estate-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PositionRealEstateInformationComponent {
  @HostBinding('class.app-position-real-estate-information') public _hostClass = true;

  public realEstateData$ = new BehaviorSubject<RealEstateDTO>(null);

  @Input() public set realEstateData(value: RealEstateDTO) {
    this.realEstateData$.next(value);
  }

  constructor() {}
}
