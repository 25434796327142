import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { SiteInfoService } from '@shared/api/site-info.service';
import { SiteInfoDTO } from '@shared/dto/accounts/models';
import { SiteInfoDialogData } from '../site-info-trigger/site-info-trigger.types';

@Component({
  selector: 'app-site-info-dialog',
  templateUrl: './site-info-dialog.component.html',
  styleUrl: './site-info-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteInfoDialogComponent {
  public data$: Observable<SiteInfoDTO>;
  public loading$ = new BehaviorSubject<boolean>(true);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SiteInfoDialogData,
    private dialogRef: MatDialogRef<SiteInfoDialogComponent, void>,
    private siteInfoService: SiteInfoService,
  ) {
    this.data$ = this.siteInfoService.getByType(this.data.type).pipe(
      tap(() => {
        this.loading$.next(false);
      }),
    );
  }

  public _onCloseClick(): void {
    this.dialogRef.close();
  }
}
