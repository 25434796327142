.app-position-information {
  width: 100%;

  .h4-m {
    text-transform: capitalize !important;
  }

  .top-gutter-2 {
    padding-top: 40px;
  }
}
